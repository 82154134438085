<template>
	<div class="ele-body">
		<el-card shadow="never">
			<div class='card-title'>
				<div class="shu"></div>
				<div>出行数据统计图</div>
			</div>
			<el-form :model="lineParams" class="ele-form-search d-flex" @keyup.enter.native="chartSearch"
				@submit.native.prevent>
				<!-- 搜索表单 -->
				<el-form-item label="省:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-select v-model="lineParams.pid" style="width: 150px ;" @change="handleChangeProv2(lineParams.pid)"
						placeholder='请选择省' clearable>
						<el-option v-for="option in provArr2" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-select v-model="lineParams.cid" style="width: 150px ;" @change="handleChangeCity2(lineParams.cid)"
						placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr2" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-select v-model="lineParams.aid" placeholder='请选择县/区' style="width: 150px ;" @change="$forceUpdate()"
						clearable>
						<el-option v-for="option in districtArr2" :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单时间:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-date-picker @change="tableTimeChange2" v-model="tableTime2" format="yyyy 年 MM 月 dd 日"
						value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item style="margin-left: 20px;">
					<el-button type="primary" @click="chartSearch" icon="el-icon-search" class="ele-btn-icon">搜索</el-button>
					<el-button @click="chartReset">重置</el-button>
				</el-form-item>
			</el-form>
			<div>
				<div style="display: flex; justify-content: space-around;">
					<ele-chart :option="visitHourChartOption" style="height:350px; width: 60%;" />
					<ele-chart :option="visitHourChartOption2" style="height: 350px; width: 38%;" />
				</div>
			</div>
		</el-card>
		<el-card shadow="never">
			<div class="card-title">
				<div class="shu"></div>
				<div>出行数据统计表</div>
			</div>
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<!-- 搜索表单 -->
				<el-form-item label="省:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-select v-model="table.where.pid" style="width: 150px ;" @change="handleChangeProv(table.where.pid)"
						placeholder='请选择省' clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-select v-model="table.where.cid" style="width: 150px ;" @change="handleChangeCity(table.where.cid)"
						placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' style="width: 150px ;" @change="$forceUpdate()"
						clearable>
						<el-option v-for="option in districtArr" :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单时间:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-date-picker @change="tableTimeChange" v-model="tableTime" format="yyyy 年 MM 月 dd 日"
						value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>

				<el-form-item style="margin-left: 20px;">
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon">搜索</el-button>
					<el-button @click="tableReset">重置</el-button>
					<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="/datas/exportOrderStatusStatTable"
						:params="exportOrderParams" :outtime='6000' v-if="permission.includes('sys:count:export')"
						ref="childDownload" />
				</el-form-item>
			</el-form>

			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 450px)"
				highlight-current-row :stripe="true">
				<template slot-scope="{index}">
					<el-table-column type="selection" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="time" label="日期" show-overflow-tooltip min-width="200" align="center" />
					<el-table-column prop="xiadan" sortable label="下单量(个)" show-overflow-tooltip min-width="140" align="center" />
					<el-table-column prop="jiedan" sortable label="接单量(个)" show-overflow-tooltip min-width="140" align="center" />
					<el-table-column prop="jiedan_rate" sortable label="接单率(%)" show-overflow-tooltip min-width="140"
						align="center" />
					<!-- <el-table-column sortable label="接单率(%)" show-overflow-tooltip min-width="140" align="center">
						<template slot-scope="{row}">
							<div class="status">{{ row.jiedan_rate}}%</div>
						</template>
					</el-table-column> -->
					<el-table-column prop="wandan" sortable label="完单量(个)" show-overflow-tooltip min-width="140" align="center" />
					<el-table-column prop="wandan_rate" sortable label="完单率(%)" show-overflow-tooltip min-width="140"
						align="center" />
					<el-table-column prop="xiadan_user" sortable label="下单乘客数(个)" show-overflow-tooltip min-width="140"
						align="center" />
					<el-table-column prop="jiedan_user" sortable label="被接单乘客数(个)" show-overflow-tooltip min-width="160"
						align="center" />
					<el-table-column prop="xiadan_finish_user" sortable label="完单乘客数(个)" show-overflow-tooltip min-width="140"
						align="center" />
					<el-table-column prop="jiedan_driver" sortable label="接单司机数(个)" show-overflow-tooltip min-width="140"
						align="center" />
					<el-table-column prop="jiedan_finish_driver" sortable label="完单司机数(个)" show-overflow-tooltip min-width="140"
						align="center" />
				</template>
			</ele-data-table>
		</el-card>
	</div>
</template>

<script>
	import EleChart from '@/components/EleChart'
	import download from '@/components/ListDownload/index'
	import {
		mapGetters
	} from "vuex";
	export default {
		components: {
			EleChart,
			download
		},
		data() {
			return {
				table: {
					url: '/datas/orderStatusStatTable',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				params: '',
				provArr: [],
				cityArr: [],
				districtArr: [],
				visitHourData: [], //折线图
				visitHourData2: [], //饼状图
				tableTime: [],
				provArr2: [],
				cityArr2: [],
				districtArr2: [],
				tableTime2: [],
				lineParams: {
					start_day: "",
					end_day: '',
					pid: '',
					cid: '',
					aid: ''
				}
			}
		},
		computed: {
			...mapGetters(["permission"]),
			// 导出数据参数
			exportOrderParams() {
				const query = this.table.where
				// const params = this.params
				console.log('query', query)
				// //console.log('-----11---params------------',params)
				// //console.log('-----22---query------------',query)
				const selectionIds = this.choose.map(item => {
					return item.time
				})
				console.log('selectionIds',selectionIds)
				return {
					ids: selectionIds,
					...query
				}
			},
			/* 折线图配置 */
			visitHourChartOption() {
				return {
					tooltip: {
						trigger: 'axis',
						formatter: function(c) {
							// console.log(c)
							let str = '';
							let temp = {
								bgcricle: '', //颜色
								axisValue: "", //时间
								valueData: '', //数值
								spacespan: '',
								seriesName:''//名字
							};
							c.forEach((item) => {
								// 做的事情
								temp.bgcricle = item.marker;
								temp.axisValue = item.axisValue;
								temp.valueData = item.value + "个";
								temp.seriesName = item.seriesName
								temp.spacespan = '<br />';
								str +=  temp.bgcricle + temp.seriesName + '：'  + temp.valueData + '<br />'
							})
							return temp.axisValue + temp.spacespan + str
						}
					},
					legend: {
						data: ['下单量', '接单量', '完单量'],
						// right: 20
					},
					xAxis: [{
						type: 'category',
						// boundaryGap: false,
						data: this.visitHourData.map(d => d.time),
						axisLabel: {
							// rotate: 20,
							// margin: 15, // 与轴线距离
						}
					}],
					yAxis: [{
						type: 'value'
					}],
					color: ['#FFC53D', '#FD4D4F', '#36CFCA'],
					series: [{
						name: '下单量',
						type: 'line',
						// smooth: true,
						// symbol: 'none',
						// areaStyle: {
						// 	opacity: 0.5
						// },
						data: this.visitHourData.map(d => d.xiadan),
						// label: {
						// 	show:true,
						// 	formatter(params) {
						// 		return toolTipFormatter(params,['个','个'])
						// 	}
						// }
					}, {
						name: '接单量',
						type: 'line',
						// smooth: true,
						// symbol: 'none',
						// areaStyle: {
						// 	opacity: 0.5
						// },
						data: this.visitHourData.map(d => d.jiedan)
					}, {
						name: '完单量',
						type: 'line',
						// smooth: true,
						// symbol: 'none',
						// areaStyle: {
						// 	opacity: 0.5
						// },
						data: this.visitHourData.map(d => d.wandan)
					}]
				}
			},
			// 饼状图配置
			visitHourChartOption2() {
				return {
					color: [
						"#37a2da",
						"#32c5e9",
						"#9fe6b8",
						"#ffdb5c",
					],
					tooltip: {
						trigger: "item",
						formatter: "{a} <br/>{b} : {c} ({d}%)"
					},
					toolbox: {
						show: true
					},
					calculable: true,
					legend: {
						// orient: "vertical",
						// icon: "rect",
						// bottom: 0,
						// left: '80%',
						top: '85%',
						x: "center",
						data: ["APP下单用户", "小程序下单用户", "电召下单用户", "老年人"],
						// textStyle: {
						// 	color: "#fff"
						// }
					},
					series: [{
						name: "下单统计",
						type: "pie",
						// radius: [10, 90],
						// roseType: "area",//南丁格尔玫瑰
						radius: '50%',
						center: ["50%", "40%"],
						// label: {
						// 	show: false
						// },
						data: this.visitHourData2,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
					}]
				}
			}
		},
		mounted() {
			this.getVisitHourData()
			this.getVisitHourData2()
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data
				this.provArr2 = data
			})
		},
		methods: {
			// 导出数据参数
			// exportOrderParams() {
			// 	const query = this.table.where
			// 	const params = this.params
			// 	console.log('query', query)
			// 	// //console.log('-----11---params------------',params)
			// 	// //console.log('-----22---query------------',query)
			// 	const selectionIds = this.choose.map(item => {
			// 		return item.id
			// 	})
			
			// 	return {
			// 		ids: selectionIds,
			// 		...query
			// 	}
			// },
			//表格时间选择
			tableTimeChange() {
				console.log('@#$#', this.tableTime)
				if (this.tableTime != null) {
					this.$set(this.table.where,'start_day', this.tableTime[0])
					this.$set(this.table.where,'end_day',this.tableTime[1])
					// this.table.where.start_day = this.tableTime[0]
					// this.table.where.end_day = this.tableTime[1]
				} else {
					this.table.where.start_day = ''
					this.table.where.end_day = ''
				}

			},
			//统计图时间选择
			tableTimeChange2() {
				if (this.tableTime2 != null) {
					this.lineParams.start_day = this.tableTime2[0]
					this.lineParams.end_day = this.tableTime2[1]
				} else {
					this.lineParams.start_day = ''
					this.lineParams.end_day = ''
				}

			},
			/* 获取折线图数据 */
			getVisitHourData() {
				this.$http.get('/datas/orderStatusStat', {
					params: this.lineParams
				}).then(res => {
					if (res.data.code == 0) {
						this.visitHourData = res.data.data
						console.log('@@@', this.visitHourData.map(d => d.xiadan + '个'))
					}
				})
			},
			// 获取饼状图数据
			getVisitHourData2() {
				this.$http.get('/datas/orderStatusStatPie', {
					params: this.lineParams
				}).then(res => {
					console.log('pie', res)
					if (res.data.code == 0) {
						this.visitHourData2 = res.data.data
					}
				})
			},
			// 统计图筛选
			chartSearch() {
				this.getVisitHourData()
				this.getVisitHourData2()
			},
			//统计图重置
			chartReset() {
				this.lineParams = {
					start_day: "",
					end_day: '',
					pid: '',
					cid: '',
					aid: ''
				}
				// this.provArr2 = [],
				this.cityArr2 = [],
					this.districtArr2 = [],
					this.tableTime2 = [],
					this.chartSearch()
			},
			// 表格重置
			tableReset() {
				this.table.where = {}
				// this.provArr = [],
				this.cityArr = [],
					this.districtArr = [],
					this.tableTime = [],
					this.$refs.table.reload()
			},
			/***选择省**/
			handleChangeProv2(e) {
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr2.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {

					var data = JSON.parse(res.data)
					this.cityArr2 = data

					/** 选择省份清空市县**/
					this.lineParams.cid = ''
					this.lineParams.aid = ''
				})
			},


			/***选择市**/
			handleChangeCity2(e) {
				if (e) {
					/** 获取被选市的cid**/
					var cid = ''
					this.cityArr2.forEach(function(item) {
						if (item.cid == e) {
							cid = item.cid
						}
					})
					/** 根据被选市的cid获取市下面的县**/
					this.$http.post('/common/area_list', {
						cid: cid
					}).then(res => {
						var data = JSON.parse(res.data)
						this.districtArr2 = data
						/** 选择省份清空县**/
						this.lineParams.aid = ''
					})
				} else {
					this.lineParams.pid = ''
					this.lineParams.aid = ''
				}
			},
			/***选择省**/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				console.log('uuuuuuuuuuuuu6666666666', e)
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {

					var data = JSON.parse(res.data)
					this.cityArr = data

					/** 选择省份清空市县**/
					this.$set(this.table.where,'cid','')
					this.$set(this.table.where,'aid','')
					// this.table.where.cid = ''
					// this.table.where.aid = ''
				})
			},


			/***选择市**/
			handleChangeCity(e) {
				if (e) {
					/** 获取被选市的cid**/
					var cid = ''
					this.cityArr.forEach(function(item) {
						if (item.cid == e) {
							cid = item.cid
						}
					})
					/** 根据被选市的cid获取市下面的县**/
					this.$http.post('/common/area_list', {
						cid: cid
					}).then(res => {
						var data = JSON.parse(res.data)
						this.districtArr = data
						/** 选择省份清空县**/
						this.$set(this.table.where,'aid','')
						// this.table.where.aid = ''
					})
				} else {
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.card-title {
		margin-bottom: 10px;
		font-size: 18px;
		display: flex;
		align-items: center;

		.shu {
			width: 5px;
			height: 20px;
			background-color: skyblue;
			margin-right: 5px;
		}
	}
</style>